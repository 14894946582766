import { Expose } from 'class-transformer'
import { IsBoolean, IsDefined, IsNumber, IsString } from 'class-validator'

export class Subscription {

  @Expose()
  @IsDefined()
  @IsNumber()
  id!: number

  @Expose()
  @IsDefined()
  @IsString()
  email!: string

  @Expose()
  @IsDefined()
  @IsString()
  name!: string

  @Expose()
  @IsDefined()
  @IsString()
  company!: string

  @Expose()
  @IsDefined()
  @IsBoolean()
  parking!: boolean

  @Expose()
  @IsDefined()
  @IsBoolean()
  inMailList!: boolean

}
