import { LoginDTO } from '../../constants/dto/login'
import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { store } from '../../store'
import { timeoutError } from '../../constants/reducer/base-reducer'
import { loginInvalidJson, loginInvalidResponse, loginStarted, loginTimeout, loginUnexpectedResponse } from '../../constants/reducer/login-reducer'
import { sessionLoginOk } from '../../constants/reducer/session-reducer'

let body: LoginDTO
export const loginAction = async () => {

    store.dispatch( loginStarted() )

    let response: Response, body: LoginDTO
    const { email, password } = store.getState().login

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/users/login`, {

            body: JSON.stringify( { email: email, password: password } ),
            headers: { 'Content-Type': `application/json` },
            method: `POST`

        } )

    } catch ( exception ) {

        store.dispatch( loginTimeout() )
        return

    }

    if ( response.status !== 200 ) {

        store.dispatch( loginUnexpectedResponse() )
        return

    }

    try {

        body = plainToClass( LoginDTO, JSON.parse( await response.text() ) )

    } catch ( error ) {

        store.dispatch( loginInvalidJson() )
        return

    }

    if ( ( await validate( body ) ).length > 0 ) {

        store.dispatch( loginInvalidResponse() )
        return

    }

    store.dispatch( sessionLoginOk( { user: body.user, token: body.token.accessToken } ) )
    return { user: body.user, token: body.token.accessToken }

}
