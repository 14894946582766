import React from 'react'
import { Provider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'
import { store } from './store'
import { router } from './router'
import 'antd/dist/reset.css'
import './App.css'

function App () {

    return (

        <React.StrictMode>

            <Provider store={ store }>

                <RouterProvider router={ router }/>

            </Provider>

        </React.StrictMode>

    )

}

export default App
