import React, { useMemo } from 'react'
import { Table } from 'antd'
import './table.scss'
import { useTranslation } from 'react-i18next'

type TableProps = {

    columns: Array<any>
    dataSource: Array<any>

}

export const DataTable: React.FC<TableProps> = ( { columns, dataSource } ) => {

    const { t } = useTranslation()

    return useMemo( () => {

        return (

            <div className="table-container">

                <Table loading={ !dataSource } columns={ columns } rowKey="id" dataSource={ dataSource } locale={ { emptyText: t( 'table-is-empty-text' ) } } pagination={ { position: [ 'bottomCenter' ] } } />

            </div>

        )

    }, [ columns, dataSource ] )

}
