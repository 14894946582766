import { Link } from 'react-router-dom'
import { useMemo } from 'react'

export const MainLogo = () => {

    return useMemo( () => {

        return (

            <Link to={ '/subscriptions' }>

                <img src="/image/OTP.BD-4b37445c.png" alt="logo"/>

            </Link>

        )

    }, [] )

}
