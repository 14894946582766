import { useEffect } from 'react'
import { RootState } from '../../store'
import { loginOk } from '../../constants/reducer/login-reducer'
import { Outlet } from 'react-router'
import { MessageModal } from '../../components/modal'
import { getMeFailure } from '../../constants/reducer/session-reducer'
import { clearError } from '../../constants/reducer/base-reducer'
import { Header } from '../../components/header'
import './default-layout.scss'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const DefaultLayout = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { t } = useTranslation()

    const { errorMessage, errorTitle } = useSelector( ( state: RootState ) => state.base )

    if ( location.pathname === `/` ) {

        return <Navigate to={ `/subscriptions` }/>

    }

    return (

        <div className={ `layout layout--default` }>

            <Header/>
            <Outlet/>

            { errorMessage && (

                <MessageModal modalVisible={ errorMessage !== '' } title={ errorTitle ?? t( 'timeout-error-title' ) } message={ errorMessage } errorTitle={ true } onOkClick={ () => {
                    if ( errorMessage === 'base-action-no-rights' ) {
                        navigate( '/login' )
                        dispatch( getMeFailure() )
                    }
                    dispatch( clearError() )
                    }
                } />

            ) }

        </div>

    )

}
