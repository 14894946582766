import { Navigate } from 'react-router-dom'
import { RootState } from '../../store'
import { useSelector } from 'react-redux'
import { cookie } from '../../constants/helper'

interface RequireAuthProps {
    children: JSX.Element;
}

export const RequireAuth = ( { children }: RequireAuthProps ) => {

    const { authenticatedUser } = useSelector( ( state: RootState ) => state.session )

    if ( authenticatedUser === null || authenticatedUser === undefined ) {

        return ( <Navigate to={ `/login?redirect=${ encodeURIComponent( window.location.pathname ) }` }/> )

    }

    return children

}
