import React from "react"
import "reflect-metadata"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import App from "./App"
import { store } from "./store"
import "./index.css"
import "./asset/scss/index.scss"
import "./i18n"

const container = document.getElementById("root")!
const root = createRoot(container)

root.render(

  <React.StrictMode>

    <Provider store={store}>

      <App />

    </Provider>

  </React.StrictMode>

)
