import { Modal, Space } from 'antd'
import { CustomButton } from '../button'
import { ExclamationCircleFilled } from '@ant-design/icons'
import './modal.scss'
import { useTranslation } from 'react-i18next'

interface MessageModalProps {

    title: string
    message: string
    onOkClick?: () => void
    modalVisible: boolean
    onModalClose?: () => void
    errorTitle?: boolean

}

export const MessageModal: React.FC<MessageModalProps> = ( {
    title, message, onOkClick, modalVisible, onModalClose, errorTitle
} ) => {

    const { t } = useTranslation()

    return (

        <Modal className="message-modal" title={ errorTitle ? (
            <Space className="title-text-h2"> <ExclamationCircleFilled style={ { color: 'red' } }/> { t( title ) }
            </Space> ) : ( <div className="title-text-h2">{ t( title ) }</div> ) } open={ modalVisible } footer={
            <CustomButton title="OK" onClick={ onOkClick ? onOkClick : onModalClose }/> } onCancel={ onOkClick ? onOkClick : onModalClose } centered>

            <div className="message-modal-error-description">{ t( message ) }</div>

        </Modal> )
}
