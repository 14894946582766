import { createBrowserRouter } from 'react-router-dom'
import { LoginLayout } from '../layout/login'
import { LoginPage } from '../pages/login'
import { NotFoundPage } from '../pages/not-found'
import { RequireAuth } from '../components/require-auth'
import { DefaultLayout } from '../layout/default'
import { Subscriptions } from '../pages/subscriptions'
import { authLoader, subscriptionsLoader } from '../constants/loader'

export const router = createBrowserRouter( [
    {
        element: (
            <RequireAuth>
                <DefaultLayout/>
            </RequireAuth>
        ),
        errorElement: null,
        path: '/',
        loader: authLoader,
        children: [
            {
                element: <Subscriptions/>,
                loader: subscriptionsLoader,
                path: 'subscriptions'
            }
        ]
    },
    {
        element: <LoginLayout/>,
        errorElement: null,
        path: '/',
        children: [
            {
                element: <LoginPage/>,
                path: `login`
            }
        ]
    },
    {
        element: <NotFoundPage/>,
        path: '*'
    }
] )
