import { LogoutOutlined } from '@ant-design/icons'
import { useMemo } from 'react'
import { cookie } from '../../constants/helper'

export const LogoutIcon = () => {
    const handleClick = async () => {

        document.cookie = `token=;expires=Wed, 31 Oct 2012 08:50:17 UTC`
        window.location.href = ``

    }

    return useMemo( () => {

        return <LogoutOutlined className="logout-button" onClick={ handleClick }/>

    }, [] )
}
