import { Link } from 'react-router-dom'
import { RootState } from '../../store'
import { CustomButton } from '../button'
import { LogoutIcon, MainLogo } from '../icon'
import './header.scss'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useState } from 'react'

export const Header = () => {

    const { t, i18n } = useTranslation()
    const { authenticatedUser } = useSelector( ( state: RootState ) => state.session )
    const [ state, setState ] = useState( { language: 'hu' } )

    const onChangeLanguageClick = ( lng: string ) => {
        setState( ( prevState ) => ( { ...prevState, language: lng } ) )
        i18n.changeLanguage( lng )
    }

    return (

        <div className="header-main">

            <div className="header-content">

                <div className="logo-container">

                    <MainLogo/>

                    <div className="language-selector" onClick={ () => onChangeLanguageClick( 'hu' === state.language ? 'en' : 'hu' ) }>
                        { `${ 'hu' === state.language ? t( 'header_langugage_en_button' ) : t( 'header_langugage_hu_button' ) }` }
                    </div>

                </div>

                <div className="menu-container">

                    <CustomButton className={ `menu-button ${ !window.location.pathname.includes( 'subscriptions' ) ? 'inactive' : '' }` } title={ t( 'header-subscriptions-button-title' ) } navLink="/subscriptions"/>

                </div>

                <div className="text-container">

                    <div>{ `${ t( 'header-logged-in-as-text' ) } ` }</div>

                    <div className="auth-user-name">

                        <Link className="auth-user-profile-link" to={ '/profile' }>

                            { authenticatedUser?.name }

                        </Link>

                    </div>

                    <LogoutIcon/>

                </div>

            </div>

        </div>

    )

}
