import { Expose, Type } from 'class-transformer'
import { ValidateNested } from 'class-validator'
import { Session, User } from '../../entity'

export class LoginDTO {

    @Expose()
    @ValidateNested()
    @Type( () => User )
    user!: User

    @Expose()
    @ValidateNested()
    @Type( () => Session )
    token!: Session

}
