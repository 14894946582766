import { MouseEventHandler } from 'react'
import { Modal } from 'antd'
import './modal.scss'
import { useTranslation } from 'react-i18next'

interface ModalProps {
    children: JSX.Element | JSX.Element[];
    modalVisible: boolean;
    onModalClose?: () => void;
    title: string;
    onConfirm?: MouseEventHandler;
    isDetail?: boolean;
}

export const CustomModal: React.FC<ModalProps> = ( {
    modalVisible, children, onModalClose, title, onConfirm, isDetail
} ) => {

    const { t } = useTranslation()

    return (

        <Modal className="modal" onCancel={ onModalClose } open={ modalVisible } centered title={ t( title ) } onOk={ onConfirm } maskClosable={ false } destroyOnClose={ true } cancelText={ t( 'message-modal-cancel-button-text' ) } okButtonProps={ !isDetail ? {
            style: { backgroundColor: '#52ae30', borderRadius: '24px' }
        } : { style: { visibility: 'hidden' } } } cancelButtonProps={ !isDetail ? { style: { borderRadius: '24px' } } : { style: { visibility: 'hidden' } } }>

            <div className="modal-body">{ children }</div>

        </Modal>

    )
}
