import { Expose, Type } from 'class-transformer'
import { ValidateNested } from 'class-validator'
import { Subscription } from '../../entity'

export class GetAllSubscriptionsDTO {

    @Expose()
    @ValidateNested()
    @Type( () => Subscription )
    subscriptions!: Subscription[]

}
