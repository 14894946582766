import { SnippetsOutlined } from '@ant-design/icons'
import { Subscription } from '../../../constants/entity'
import { setSubscription } from '../../../constants/reducer/subscription-reducer'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useMemo } from 'react'

type MessageColumnsProps = {

    handleModalVisible: () => void

}

export const SubscriptionColumns = ( { handleModalVisible }: MessageColumnsProps ) => {

    const { t } = useTranslation()
    const dispatch = useDispatch()

    const handleGetMessage = ( record: Subscription ) => {

        dispatch( setSubscription( { subscription: record } ) )
        handleModalVisible()

    }

    return useMemo( () => {
        return [
            {
                title: ` `,
                key: t( 'subscriptions-page-column-id-title' ),
                dataIndex: 'id',
                width: '5%'
            },
            {
                title: t( 'subscriptions-page-column-name-title' ),
                key: t( 'subscriptions-page-column-name-title' ),
                dataIndex: 'name',
                width: '25%'
            },
            {
                title: t( 'subscriptions-page-column-email-title' ),
                key: t( 'subscriptions-page-column-email-title' ),
                dataIndex: 'email',
                width: '25%'
            },
            {
                title: t( 'subscriptions-page-column-company-title' ),
                key: t( 'subscriptions-page-column-company-title' ),
                dataIndex: 'company',
                width: '25%'
            },
            {
                title: t( 'subscriptions-page-column-parking-title' ),
                key: t( 'subscriptions-page-column-parking-title' ),
                dataIndex: 'parking',
                render: ( text: string, record: Subscription ) => record.parking ? `Igen` : `Nem`,
                width: '10%'
            },
            {
                title: ' ',
                key: 'details',
                render: ( text: string, record: Subscription ) => {
                    return <SnippetsOutlined onClick={ () => handleGetMessage( record ) }/>
                },
                width: '10%'
            }
        ]
    }, [] )
}
