import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Subscription } from '../entity'
import { SubscriptionState } from '../state'

const initialState: SubscriptionState = {
    subscriptions: [],
    searchText: '',

    subscriptionActionStart: false,
    subscriptionErrorMessage: '',

    name: '',
    email: '',
    company: '',
    parking: false,
    inMailList: false
}

export const messageSlice = createSlice( {
    name: 'message',
    initialState,
    reducers: {
        setSubscriptionActionStart: ( state, action: PayloadAction<boolean> ) => {
            state.subscriptionActionStart = action.payload
        },
        setSubscriptionErrorMessage: ( state, action: PayloadAction<string> ) => {
            state.subscriptionErrorMessage = action.payload
        },
        setSubscriptions: ( state, action: PayloadAction<{ subscriptions: Subscription[] }> ) => {
            state.subscriptionActionStart = false
            state.subscriptions = action.payload.subscriptions
            state.subscriptionErrorMessage = ''
        },
        setSubscription: ( state, action: PayloadAction<{ subscription: Subscription }> ) => {
            state.subscriptionActionStart = false
            state.subscriptionErrorMessage = ''

            state.name = action.payload.subscription.name
            state.email = action.payload.subscription.email
            state.company = action.payload.subscription.company
            state.parking = action.payload.subscription.parking
            state.inMailList = action.payload.subscription.inMailList

        },
        setSubscriptionSearchText: ( state, action: PayloadAction<string> ) => {
            state.searchText = action.payload
        },
        resetSubscription: ( state ) => {
            state.searchText = ''
            state.subscriptionActionStart = false
            state.subscriptionErrorMessage = ''
        }
    }
} )

export const { setSubscriptionActionStart, setSubscriptionErrorMessage, setSubscriptions, resetSubscription, setSubscriptionSearchText, setSubscription } = messageSlice.actions
