import { LoginDTO } from '../../constants/dto/login'
import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { store } from '../../store'
import { timeoutError } from '../../constants/reducer/base-reducer'
import { loginInvalidJson, loginInvalidResponse, loginStarted, loginTimeout, loginUnexpectedResponse } from '../../constants/reducer/login-reducer'
import { sessionLoginOk } from '../../constants/reducer/session-reducer'
import { setSubscriptionActionStart, setSubscriptions } from '../../constants/reducer/subscription-reducer'
import { GetAllSubscriptionsDTO } from '../../constants/dto/subscription'
import { cookie } from '../../constants/helper'

let body: LoginDTO
export const getAllSubscriptionsAction = async () => {

    store.dispatch( setSubscriptionActionStart( true ) )

    let response: Response, body: GetAllSubscriptionsDTO

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/subscriptions`, {
            headers: { 'Authorization': `Bearer ${ cookie( `token` ) }` },
        } )

    } catch ( exception ) {

        return

    }

    if ( response.status !== 200 ) {

        return

    }

    try {

        body = plainToClass( GetAllSubscriptionsDTO, JSON.parse( await response.text() ) )

    } catch ( error ) {

        return

    }

    if ( ( await validate( body ) ).length > 0 ) {

        return

    }

    store.dispatch( setSubscriptions( { subscriptions: body.subscriptions } ) )

}
