import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { BaseState } from '../state'

const initialState: BaseState = {
    errorTitle: '',
    errorMessage: '',
    requestStart: false
}

export const baseSlice = createSlice( {
    name: 'base',
    initialState,
    reducers: {
        timeoutError: ( state ) => {
            state.errorMessage = 'timeout-error-message'
            state.requestStart = false
        },
        basicErrorMessage: ( state, action: PayloadAction<string> ) => {
            state.errorMessage = action.payload
            state.requestStart = false
        },
        clearError: ( state ) => {
            state.errorTitle = ''
            state.errorMessage = ''
            state.requestStart = false
        },
        requestStart: ( state, action: PayloadAction<boolean> ) => {
            state.requestStart = action.payload
        }
    }
} )

export const { timeoutError, clearError, basicErrorMessage, requestStart } = baseSlice.actions
