import { MeDTO } from '../../constants/dto/login'
import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { store } from '../../store'
import { getMeOk } from '../../constants/reducer/session-reducer'
import { cookie } from '../../constants/helper'

export const meAction = async () => {

    let body: MeDTO, response: Response

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/users/me`, {

            headers: { 'Authorization': `Bearer ${ cookie( `token` ) }` }

        } )

    } catch ( exception ) {

        return false

    }

    if ( response.status !== 200 ) {

        return false

    }

    try {

        body = plainToClass( MeDTO, JSON.parse( await response.text() ) )

    } catch ( error ) {

        return

    }

    if ( ( await validate( body ) ).length > 0 ) {

        return

    }

    store.dispatch( getMeOk( { user: body.user } ) )
    return true

}
