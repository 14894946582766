import { Form } from 'antd'
import { InputText } from '../../../components/input'
import { RootState } from '../../../store'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

export const SubscriptionDetailsForm = () => {

    const [ form ] = Form.useForm()
    const { email, name, company, parking, inMailList } = useSelector( ( state: RootState ) => state.message )
    useEffect( () => { form.setFieldsValue( { email: email, name: name, company: company, parking: parking, inMailList: inMailList } ) }, [ email, name, company, parking, inMailList, form ] )

    return (

        <Form form={ form } name="message-details-form" labelCol={ { span: 0 } } wrapperCol={ { span: 24 } } autoComplete="off">
            <Form.Item name="email">

                <InputText value={ email } disabled={ true } onChange={ () => null }/>

            </Form.Item>

            <Form.Item name="name">

                <InputText value={ name } disabled={ true } onChange={ () => null }/>

            </Form.Item>

            <Form.Item name="company">

                <InputText value={ company } disabled={ true } onChange={ () => null }/>

            </Form.Item>

        </Form>

    )

}
