import { useRef, useEffect, useState, ChangeEvent } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { InputText } from '../../components/input'
import { RootState } from '../../store'
import { MessageModal } from '../../components/modal'
import { CustomButton } from '../../components/button'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { changeEmail, changePassword, setAlertMessage } from '../../constants/reducer/login-reducer'
import { loginAction } from '../../actions/login'

export const LoginPage = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { authenticatedUser } = useSelector( ( state: RootState ) => state.session )
    const { email, password, alertMessage, loginStarted, remainingTime } = useSelector( ( state: RootState ) => state.login )
    const { t } = useTranslation()

    const handleAlertMessage = () => dispatch( setAlertMessage( `` ) )
    const handleEmailChange = ( email: string ) => dispatch( changeEmail( email ) )
    const handlePasswordChange = ( password: string ) => dispatch( changePassword( password ) )
    const handleLogin = async () => {
        const result = await loginAction()
        if ( result ) navigate( `/subscriptions` )
    }

    const timerRef = useRef<NodeJS.Timeout>()
    const [ timer, setTimer ] = useState<number>( null! )

    useEffect( () => {

        if ( remainingTime ) {
            clearTimeout( timerRef.current )
            timerRef.current = setInterval( () => {
                const remaining = Math.floor( ( remainingTime.getTime() - new Date().getTime() ) / 1000 )
                setTimer( remaining )
            }, 100 )
        }
        if ( timer === 0 ) {
            setTimer( null! )
            clearInterval( timerRef.current )
            handleAlertMessage()
        } else {
            return () => {
                clearInterval( timerRef.current )
            }
        }
    }, [ handleAlertMessage, remainingTime, timer ] )


    if ( authenticatedUser ) {

        const url = new URL( window.location.href ).searchParams.get( 'redirect' ) ?? '/subscriptions'
        return <Navigate to={ url }/>

    }

    return (

        <main className="page login-page">

            <img className="logo" src="/image/otp_alapkezelo_white_logo.svg" alt="logo"/>

            <div className="input-container">
                <InputText value={ email } onChange={ handleEmailChange } placeholder={ t( 'login-email-text' ) } isLogin={ true }/>
                <InputText value={ password } onChange={ handlePasswordChange } isPassword={ true } placeholder={ t( 'login-password-text' ) } isLogin={ true }/>
            </div>

            <CustomButton onClick={ handleLogin } disabled={ loginStarted !== null } title={ t( 'login-login-button-text' ) }/>

            { alertMessage !== '' && (

                <MessageModal modalVisible={ alertMessage !== '' } title={ t( 'login-message-modal-error' ) } message={ remainingTime && timer > 0 && timer !== null ? `${ t( alertMessage ) }${ timer }` : alertMessage } onOkClick={ handleAlertMessage }/>

            ) }

        </main>

    )

}
