import './not-found-page.scss'
import { useTranslation } from 'react-i18next'

export const NotFoundPage = () => {

    const { t } = useTranslation()

    return (

        <div className="error-container">

            <div className="error-title">404</div>
            <div className="error-subtitle">{ t( 'page-not-found-title' ) }</div>
            <div>{ t( 'page-not-found-subtitle' ) }</div>

        </div>

    )

}
